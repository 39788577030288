<template>
  <div class="new-con">
    <section class="page-title text-center">
      <div class="container">
        <div class="content-box">
          <h1>{{ $t('Contact Us') }}</h1>
        </div>
      </div>
    </section>

    <!--SECTION START-->
    <section class="container text-center" style="padding-top: 36px;">
      <div>
        <p v-if="lang == 'fr'">
          ce service n'est pas disponible pour la réservation en ligne.
          <br />
          Si vous souhaitez réserver un massage à Domicile | Hôtel | Bureau nous
          vous demandons de nous contacter par téléphone ou bien par e-mail ou
          sur WhatsApp.
          <br />
          Au plaisir de vous servir.
          <br />
          Phone number : +352761107278
          <br />
          Email : egypta.massage@gmail.com
          <br />
          Wattsapp : +352761107278
        </p>
        <p v-if="lang == 'en'">
          This service is not available for online booking.
          <br />
          If you want to book a massage at home | Hotel | Office we ask you to
          contact us by phone or by e-mail or WhatsApp. Looking forward to
          serving you.
          <br />
          Phone number: +352761107278
          <br />
          Email: egypta.massage@gmail.com
          <br />
          Wattsapp: +352761107278
        </p>
        <p v-if="lang == 'de'">
          Dieser Service ist nicht für die Online-Buchung verfügbar.
          <br />
          Wenn Sie eine Massage zu Hause buchen möchten | Hotel | Office bitten
          wir Sie, uns telefonisch oder per E-Mail oder WhatsApp zu
          kontaktieren.
          <br />
          Ich freue mich darauf, Ihnen zu dienen.
          <br />
          Telefonnummer: +352761107278
          <br />
          E-Mail: egypta.massage@gmail.com
          <br />
          Wattsapp: +352761107278
        </p>
        <p v-if="lang == 'it'">
          Questo servizio non è disponibile per la prenotazione online.
          <br />
          Se vuoi prenotare un massaggio a domicilio | in hotel | Al lavoro vi
          chiediamo di contattarci telefonicamente o via e-mail o WhatsApp. Non
          vedo l'ora di servirti.
          <br />
          Numero di telefono: +352761107278
          <br />
          E-mail: egypta.massage@gmail.com
          <br />
          Wattapp: +352761107278
        </p>
        <p v-if="lang == 'lb'">
          Dëse Service ass net verfügbar fir online Buchung.
          <br />
          Wann Dir wëllt eng Massage doheem buchen | Hotel | Op der aarbecht mir
          bieden Iech eis iwwer Telefon oder per E-Mail oder WhatsApp ze
          kontaktéieren.
          <br />

          Telefonsnummer: +352761107278
          <br />
          E-Mail: egypta.massage@gmail.com
          <br />
          Wattsapp: +352761107278
        </p>
        <p v-if="lang == 'pt'">
          Este serviço não está disponível para reserva online.
          <br />
          Se quiser marcar uma massagem em casa | Hotel | Office pedimos que
          entre em contato conosco por telefone ou por e-mail ou WhatsApp.
          <br />

          Número de telefone +352761107278
          <br />
          E-mail: egypta.massage@gmail.com
          <br />
          Wattsapp: +352761107278
        </p>
        
      </div>
    </section>
    <!-- <conactus style="background-color: white;padding-top: 20px;" /> -->
  </div>
</template>

<script>
import conactus from './home/conactus';

export default {
  components: {
    conactus,
  },
  data() {
    return {
      lang: localStorage.lang || 'en',
    };
  },
};
</script>
<style>
.new-con h2 {
  color: #f26838;
  text-transform: uppercase;
  margin-top: 8px;
  font-size: 24px;
  font-weight: 700;
}
.new-con h2 span {
  color: #13293d;
  text-transform: uppercase;
  margin-top: 8px;
  font-size: 24px;
  font-weight: 700;
}
.new-con p a {
  color: #8b4513;
}
.new-con h4 {
  text-transform: uppercase;
  padding-bottom: 12px;
  color: #13293d;
  font-weight: 700;
}
</style>
